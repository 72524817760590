import React from "react";
import { useNewsLaterContext } from "../../context/NewsLaterContext";
import Container from "../common/Container";
import PrimaryButton from "../atoms/PrimaryButton";

export default function HeroSection() {
  const { dispatch, actions } = useNewsLaterContext();
  return (
    <div className="w-full container mx-auto px-[70px] py-[100px] ">
      <div className="w-full mx-auto lg:gap-0">
        <div className="lg:flex lg:items-center">
          <div className="z-10 md:pb-3 lg:-mr-4">
            <h1 className="text-[40px] leading-[48px] font-semibold text-[#0D283E] md:text-[56px] md:leading-[67px]  flex items-start md:items-center flex-col md:flex-row">
              <span className="inline-block ">Introducing&nbsp;</span>
              <span className=" bg-clip-text text-transparent  bg-gradient-to-r from-[#4789CD] to-[#28D4F5] inline-block pt-1.5 md:pt-0">
                FET
              </span>
            </h1>
            <div className="max-w-[640px]">
              <h2 className=" md:font-normal font-semibold pt-4 pb-10 text-[#0D283E]  text-[18px] md:text-[22px]">
                FET coin is a digital currency that serves as a medium of
                exchange within the Fetch.ai ecosystem
              </h2>
            </div>
            <div>
              <a
                href="https://fetch.ai/get-fetch-wallet"
                target="_blank"
                rel="noreferrer"
              >
                <PrimaryButton
                  classes="md:mr-[28px] mx-auto mb-4 md:mb-0"
                  label="Get Wallet"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
