import React, { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import CrossIcon from "../icons/CrossIcon";

const YoutubeModal = ({ link, open, closeModal }) => {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={closeModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#122133] bg-opacity-80 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="bg-gray-200 relative transform overflow-hidden rounded-[32px] text-left shadow-xl transition-all sm:my-8 py-[32px] px-[18px] sm:py-[48px] sm:px-[44px]
                      md:w-[60%] h-full"
              >
                <CrossIcon
                  className="cursor-pointer w-3 h-3 absolute top-8 right-9 text-[#A2B7C1] hover:text-night-rider"
                  onClick={closeModal}
                />
                <Dialog.Title
                  as="h3"
                  className="text-[28px] font-bold font-ubuntu text-center uppercase text-night-rider leading-8"
                >
                  How to{" "}
                  <span className="inline-block text-gradient-one">stake</span>
                </Dialog.Title>
                <Dialog.Description className="mt-3 mx-auto">
                  <iframe
                    title="Staking Video"
                    width="100%"
                    height="100%"
                    src={link}
                    allowFullScreen
                  ></iframe>
                </Dialog.Description>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default YoutubeModal;
