import React from "react";
import { ecosystem } from "./constant";
import payFet from "../../images/payFet.svg";

function BuyFet() {
  return (
    <>
      <section className="flex items-center justify-center bg-white ">
        <div className="container py-[100px] lg:px-[100px] mx-auto px-[30px] bg-white w-full">
          <div className="mx-auto w-full flex flex-col lg:grid lg:grid-cols-2 gap-[20px]">
            <div className="w-full md:relative ">
              <div className="relative flex items-center justify-center scale-x-110 md:scale-100">
                <img
                  src={payFet}
                  className="object-cover md:w-full rounded-2xl md:h-full"
                  alt="mission 3"
                />
              </div>
            </div>

            <div className="relative z-10 flex flex-col justify-center md:mt-0 lg:ml-16">
              <h4 className="text-[46px] leading-12 text-left tracking-normal mb-4 font-bold">
                Pay in&nbsp;
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#4789CD] to-[#3CD7F6]">
                  FET
                </span>
              </h4>
              <div className="mb-6 text-left">
                <p className="text-[18px] md:text-[22.77px] font-normal">
                  You can pay for services provided by Fetch.ai with FET,
                  including network transactions fees.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mx-auto container text-center py-[100px]">
        <div>
          <h1 className="font-bold text-[46px]">
            Buy{" "}
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#4789CD] to-[#3CD7F6]">
              FET
            </span>
          </h1>
          <p className="text-[18px] md:text-[22.77px] font-semibold mx-auto pt-[40px] md:pt-[30px]">
            It is possible to purchase FET on centralized exchanges or swap
            tokens for FET on decentralized exchanges (DEX).
          </p>
        </div>
        <div className="flex lg:flex-row flex-col items-center justify-center gap-[20px] mx-auto pt-[60px] px-[20px]">
          <div className="bg-white cardsBoarder rounded-[20px] py-[40px] px-[20px] md:py-[40px] md:px-[40px] w-1/2">
            <p className="text-[22.77px] font-semibold mb-4">
              Centralized Exchanges (CEX)
            </p>
            <p className="text-[16px] mb-4">
              Styled on traditional currency exchanges, CEX take custody of your
              assets in order to provide exchange services.
              <br />
              *centralized exchanges may vary by region.
            </p>
          </div>
          <div className="absolute w-[60px] h-[60px] bg-gradient-to-br from-blue-400 to-blue-600 rounded-full ring-[20px] ring-[#ebf1fb] flex items-center justify-center text-white text-xl font-semibold">
            OR
          </div>
          <div className="bg-white cardsBoarder py-[40px] px-[20px] md:py-[40px] md:px-[40px] w-1/2">
            <p className="text-[22.77px] font-semibold mb-4">
              Decentralized Exchanges (DEX)
            </p>
            <p className="text-[16px] mb-4">
              A peer-to-peer digital currency exchange that you connect to via
              your own digital wallet, thus retaining control over your own FET.
            </p>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center pt-[60px] px-[20px]">
          <div className="relative grid  grid-cols-2 gap-x-[12px]  md:gap-x-[18px]  mx-auto md:grid-cols-4">
            {ecosystem.map((item, index) => (
              <div
                key={index}
                className="relative bg-gradient-to-br mt-10 from-[#4789CD] via-[#478acd00] to-[#478acd7a] rounded-[12px] md:rounded-[16px] px-[1.5px] w-full md:w-full lg:w-[calc(25% - 16px)] xl:w-[calc(25% - 20px)] "
              >
                <div className="absolute top-[-12px] left-[40px] transform -translate-x-1/2 bg-black min-w-[44px] text-white px-2 py-1 rounded-xl">
                  <p className="text-xs">{item.type}</p>
                </div>
                <div
                  style={{
                    boxShadow: "20px 20px 60px 0px rgba(71, 137, 205, 0.16)",
                  }}
                  className="rounded-[12px] md:rounded-[16px] md:px-[18px] px-[13px]  bg-white w-full md:w-full lg:w-[calc(25% - 16px)] xl:w-[calc(25% - 20px)]"
                >
                  <img src={item.logoLink} alt="ecosystemImage" className="" />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex items-center justify-center pt-[40px] text-[#0D283E] px-[20px]">
          <div className="rounded-xl border border-[#0D283E] px-[20px] py-[10px] mr-[18px]">
            <p>N - Native FET</p>
          </div>
          <div className="rounded-xl border border-[#0D283E] px-[20px] py-[10px]">
            <p>DEX = Decentralized Exchange</p>
          </div>
        </div>
      </section>
    </>
  );
}

export default BuyFet;
