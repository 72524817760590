import React, { useState } from "react";
import PrimaryButton from "../atoms/PrimaryButton";
import walletFet from "../../images/Wallet_FET.svg";
import fetchWallet from "../../images/fetchai_wallet.svg";
import greenGlow from "../../images/green_glow.svg";
import YoutubeModal from "../modal/youtubeVideo";

const NativeFET = () => {
  const [modalState, setModalState] = useState(false);

  return (
    <>
      <section className="flex items-center justify-center bg-white ">
        <div className="md:py-[80px] mx-auto container py-[50px] bg-white px-[20px]">
          <div className="bg-white mx-auto w-full flex flex-col lg:grid lg:grid-cols-2 md:gap-[80px]">
            <div className="relative z-10 flex flex-col justify-center order-2 w-full lg:order-1 md:mt-0 lg:w-auto">
              <h4 className="text-[40px] pt-[60px] md:pt-0 md:text-[46px] leading-12 text-left tracking-normal mb-4 font-bold">
                Staking&nbsp;
                <br className="block md:hidden" />
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#4789CD] to-[#3CD7F6]">
                  Native FET
                </span>
              </h4>
              <div className="mb-6 text-left">
                <p className="text-[18px] font-normal">
                  You can choose to stake FET to participate in securing the
                  Fetch.ai network via its Proof-of-Stake (PoS) consensus
                  mechanism and earn rewards in return for contributing to
                  validator nodes.
                </p>
                <div className="mt-[30px]">
                  <PrimaryButton
                    classes="mx-auto md:mb-0"
                    label="Learn more about Staking"
                    action={() => {
                      setModalState(true);
                    }}
                  />
                  <YoutubeModal
                    link="https://www.youtube.com/embed/41Z1PzXj-dE"
                    open={modalState}
                    closeModal={() => setModalState(false)}
                  />
                </div>
              </div>
            </div>

            <div className="order-1 w-full lg:order-2 md:block md:relative">
              <div className="relative flex items-center justify-center h-full scale-x-110 md:scale-100">
                <img
                  src={fetchWallet}
                  className="md:w-full rounded-[20px] object-cover h-[450px] w-[450px] md:h-full"
                  alt="mission 3"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="px-[20px] mb-[30px] container flex justify-center items-center  md:py-[100px] md:px-[34px] mx-auto">
        <div className="flex flex-col w-full gap-8 mx-auto lg:grid lg:grid-cols-2">
          <div className="w-full md:relative lg:w-3/4">
            <div className="relative flex items-center justify-center h-96">
              <img
                src={walletFet}
                className="object-cover w-full rounded-2xl"
                alt="mission 3"
              />
              <img
                src={greenGlow}
                className="absolute object-cover w-full rounded-2xl md:-top-40"
                alt="mission 3"
              />
            </div>
          </div>

          <div className=" flex flex-col justify-center z-10 relative md:mt-0 max-w-[460px] ">
            <h4 className="text-[40px] md:text-[46px] leading-12 text-left tracking-normal mb-4 font-bold">
              Fetch.ai&nbsp;
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#4789CD] to-[#3CD7F6]">
                Wallet
              </span>
            </h4>
            <div className="mb-6 text-left">
              <p className="md:text-[22.77px] text-[18px] font-normal">
                Download the native FET wallet from either Google Chrome
                Webstore or Firefrox Webstore.
              </p>
              <a
                href="https://fetch.ai/get-fetch-wallet"
                target="_blank"
                rel="noreferrer"
              >
                <PrimaryButton
                  classes="mx-auto  md:mb-0 mt-[30px]"
                  label="Download wallet"
                />
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NativeFET;
