import React from "react";
import HeroSection from "../components/fet-tokens/HeroSection";
import Layout from "../components/layout";
import BuyFet from "../components/fet-tokens/BuyFet";
import NativeFET from "../components/fet-tokens/StakingNativeFET";
import { useBannerWidthContext } from "../context/BannerWidthContext";
import { useBannerDimensions } from "../hooks/useBannerDimensions";

function FetToken() {
  const { dispatch } = useBannerWidthContext();
  const bannerRef = useBannerDimensions(dispatch);
  return (
    <Layout headerClass="relative">
      <div ref={bannerRef} className="lg:h-[557px]">
        <HeroSection />
      </div>
      <BuyFet />
      <NativeFET />
    </Layout>
  );
}
export default FetToken;
