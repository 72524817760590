import osmosis from "../../images/fet-partners/osmosis.svg";
import uniswap from "../../images/fet-partners/uniswap.svg";
import binance from "../../images/fet-partners/binance.svg";
import binanceus from "../../images/fet-partners/binance.us.svg";
import hitbtc from "../../images/fet-partners/hitbtc.svg";
import kraken from "../../images/fet-partners/kraken.svg";
import gateio from "../../images/fet-partners/gateio.svg";
import mexc from "../../images/fet-partners/mexc.svg";
import huobi from "../../images/fet-partners/huobi.svg";
import coinbase from "../../images/fet-partners/coinbase.svg";
import crypto from "../../images/fet-partners/crypto.svg";
import bitfinex from "../../images/fet-partners/bitfinex.svg";
export const ecosystem = [
  {
    type: "DEX",
    logoLink: osmosis,
  },
  {
    type: "DEX",
    logoLink: uniswap,
  },
  {
    type: "N",
    logoLink: binance,
  },
  {
    type: "N",
    logoLink: binanceus,
  },
  {
    type: "N",
    logoLink: hitbtc,
  },
  {
    type: "N",
    logoLink: kraken,
  },
  {
    type: "N",
    logoLink: gateio,
  },
  {
    type: "N",
    logoLink: mexc,
  },
  {
    type: "N",
    logoLink: huobi,
  },
  {
    type: "DEX",
    logoLink: coinbase,
  },
  {
    type: "DEX",
    logoLink: crypto,
  },
  {
    type: "DEX",
    logoLink: bitfinex,
  },
];
